import React from 'react';
import { Card, Row, Col, ProgressBar, Badge } from 'react-bootstrap';
import { MapPin, Clock, Briefcase, Award, Shield, DollarSign, Truck } from 'react-feather';

const DriverOverview = ({ driverDetails, loads }) => {
  // Helper functions to calculate percentages and summaries
  const totalLoads = loads.length;
  const activeLoads = loads.filter(l => l.load_status === 'In Transit').length;
  const completedLoads = loads.filter(l => l.load_status === 'Completed').length;
  const totalEarnings = loads.reduce((sum, l) => sum + (l.load_tender_details?.rate || 0), 0);
  const totalMileage = driverDetails.totalMileage || 0;
  const activeLoadPercentage = (activeLoads / totalLoads) * 100 || 0;
  const completedLoadPercentage = (completedLoads / totalLoads) * 100 || 0;

  return (
    <div className="driver-overview">
      <Row>
        {/* Performance Summary */}
        <Card className="shadow-sm mb-4">
          <Card.Header className="text-white bg-light d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Performance Summary</h5>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={3} className="text-center">
                <h4>{totalLoads}</h4>
                <p className="text-muted">Total Loads</p>
                <ProgressBar now={(totalLoads / 100) * 100} variant="info" className="mt-2" />
              </Col>
              <Col md={3} className="text-center">
                <h4>{totalMileage} miles</h4>
                <p className="text-muted">Total Miles</p>
                <ProgressBar now={(totalMileage / 1000) * 100} variant="success" className="mt-2" />
              </Col>
              <Col md={3} className="text-center">
                <h4>${totalEarnings.toFixed(2)}</h4>
                <p className="text-muted">Total Earnings</p>
                <ProgressBar now={(totalEarnings / 1000) * 100} variant="warning" className="mt-2" />
              </Col>
              <Col md={3} className="text-center">
                <h4>{activeLoads}</h4>
                <p className="text-muted">Active Loads</p>
                <ProgressBar now={activeLoadPercentage} variant="danger" className="mt-2" />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={3} className="text-center">
                <h4>{completedLoads}</h4>
                <p className="text-muted">Completed Loads</p>
                <ProgressBar now={completedLoadPercentage} variant="primary" className="mt-2" />
              </Col>
              <Col md={3} className="text-center">
                <h4>{driverDetails.yearsExperience || 'N/A'}</h4>
                <p className="text-muted">Years of Experience</p>
              </Col>
              <Col md={3} className="text-center">
                <h4>{driverDetails.safetyScore || 'N/A'}</h4>
                <p className="text-muted">Safety Score</p>
              </Col>
              <Col md={3} className="text-center">
                <h4>{driverDetails.hosStatus || 'N/A'}</h4>
                <p className="text-muted">Hours of Service</p>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {/* Personal Information */}
        <Col md={6}>
          <Card className="mb-4 shadow-sm">
            <Card.Header className="bg-light">
              <h5 className="mb-0">Personal Information</h5>
            </Card.Header>
            <Card.Body>
              <Row className="mb-2">
                <Col md={4} className="text-muted">Full Name</Col>
                <Col>{driverDetails.fullName}</Col>
              </Row>
              <Row className="mb-2">
                <Col md={4} className="text-muted">Contact Email</Col>
                <Col>{driverDetails.driver_email}</Col>
              </Row>
              <Row className="mb-2">
                <Col md={4} className="text-muted">Phone Number</Col>
                <Col>{driverDetails.driver_phone}</Col>
              </Row>
              <Row className="mb-2">
                <Col md={4} className="text-muted">Home Address</Col>
                <Col>
                  {`${driverDetails.driver_address.street || ''}, 
                  ${driverDetails.driver_address.city || ''}, 
                  ${driverDetails.driver_address.state || ''}, 
                  ${driverDetails.driver_address.zip || ''}`}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        {/* Professional Details */}
        <Col md={6}>
          <Card className="mb-4 shadow-sm">
            <Card.Header className="bg-light">
              <h5 className="mb-0">Professional Details</h5>
            </Card.Header>
            <Card.Body>
              <Row className="mb-2">
                <Col md={4} className="text-muted">
                  <Briefcase size={16} className="me-2" />
                  Employment Type
                </Col>
                <Col>{driverDetails.type}</Col>
              </Row>
              <Row className="mb-2">
                <Col md={4} className="text-muted">
                  <Shield size={16} className="me-2" />
                  Qualifications
                </Col>
                <Col>
                  {driverDetails.driver_qualifications.CDL && <p>Class A CDL</p>}
                  {driverDetails.driver_qualifications.endorsements?.length > 0 && (
                    <ul className="list-unstyled mb-0">
                      {driverDetails.driver_qualifications.endorsements.map((endorsement, index) => (
                        <li key={index}>
                          <Badge bg="info" className="me-1">
                            {endorsement}
                          </Badge>
                        </li>
                      ))}
                    </ul>
                  )}
                </Col>
              </Row>

              <Row className="mb-2">
                <Col md={4} className="text-muted">
                  <MapPin size={16} className="me-2" />
                  License Details
                </Col>
                <Col>
                  {driverDetails.driver_license_state.state || 'N/A'} -
                  {driverDetails.driver_license_number || 'N/A'}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col md={4} className="text-muted">
                  <Clock size={16} className="me-2" />
                  Hours of Service
                </Col>
                <Col>{driverDetails.hosStatus || 'N/A'}</Col>
              </Row>
              <Row className="mb-2">
                <Col md={4} className="text-muted">
                  <Award size={16} className="me-2" />
                  Awards
                </Col>
                <Col>{driverDetails.awards || 'No awards recorded.'}</Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Additional Notes */}
      <Card className="shadow-sm mt-4">
        <Card.Header className="bg-light">
          <h5 className="mb-0">Additional Notes</h5>
        </Card.Header>
        <Card.Body>
          <p>{driverDetails.driver_notes || 'No additional notes have been recorded.'}</p>
        </Card.Body>
      </Card>
    </div>
  );
};

export default DriverOverview;
