import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Star } from 'react-feather'
import { Table } from "antd";
import Select from 'react-select';
import CompaniesModal from '../../../components/modelpopup/CompaniesModal';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import AddNotes from '../../../components/modelpopup/Crm/AddNotes';
import { ENDPOINT } from '../../../Config/Constants';


const LoadShipmentPage = () => {
  const navigate = useNavigate()
  const [showFilter, setShowFilter] = useState(false);
  const [fieldInputs, setFieldInputs] = useState(false);
  const [focused, setFocused] = useState(false);
  const [focusedTwo, setFocusedTwo] = useState(false);
  const [focusedThree, setFocusedThree] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueTwo, setInputValueTwo] = useState("");
  const [inputValueThree, setInputValueThree] = useState("");
  const [data, setData] = useState([]);


  useEffect(() => {
    // Fetch loads data from API
    const fetchLoads = async () => {
        try {
            const response = await fetch(`${ENDPOINT}/api/loads`);
            const data = await response.json();
            
            const formattedData = data.map((load) => ({
                id: load.load_id,
                customerId: load.customer_id,
                logo: `https://randomuser.me/api/portraits/men/${load.customer_id}.jpg`, // Random logo URL
                origin: {
                    companyName: load.load_origin_company_name,
                    address: `${load.load_origin_address.street}, ${load.load_origin_address.city}, ${load.load_origin_address.state}, ${load.load_origin_address.zipcode}`,
                    contact: load.load_origin_contact,
                    numbers: load.load_origin_numbers,
                    windowStart: load.load_origin_window_start,
                    windowEnd: load.load_origin_window_end,
                },
                stop: {
                    companyName: load.load_stop_company_name,
                    address: `${load.load_stop_address.street}, ${load.load_stop_address.city}, ${load.load_stop_address.state}, ${load.load_stop_address.zipcode}`,
                    contact: load.load_stop_contact,
                    numbers: load.load_stop_numbers,
                    windowStart: load.load_stop_window_start,
                    windowEnd: load.load_stop_window_end
                },
                destination: {
                    companyName: load.load_destination_company_name,
                    address: `${load.load_destination_address.street}, ${load.load_destination_address.city}, ${load.load_destination_address.state}, ${load.load_destination_address.zipcode}`
                },
                weight: load.load_weight || 0,
                equipment: load.load_equipment_type || 'Unknown',
                rate: load.load_tender_details?.rate || 0,
                tender_id: load.load_tender_details?.tender_id || 0,
                status: load?.load_status || 'Available',
                carrier: load.load_tender_details?.carrier_name || 'Unassigned'
            }));

    console.log(formattedData)



            setData(formattedData);
        } catch (error) {
            console.error('Error fetching loads:', error);
        }
    };

    fetchLoads();
}, []);

const columns = [
  {
    title: "Logo",
    dataIndex: "logo",
    render: (logoUrl) => (
      <div style={{width: "40px", height: "40px"}}>
        <img
          src={logoUrl}
          alt="Company Logo"
          style={{ width: "100%", height: "100%", objectFit: "contain",borderRadius: 50 }}
        />
      </div>
    ),
  },
  {
    title: "Tender ID",
    dataIndex: "tender_id",
    sorter: (a, b) => a.tender_id.localeCompare(b.tender_id),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text) => (
      <div className={`badge ${text === "In Transit" ? "badge-soft-success" : "badge-soft-warning"}`}>
        {text}
      </div>
    ),
    sorter: (a, b) => a.status.localeCompare(b.status),
  },
  {
    title: "Carrier",
    dataIndex: "carrier",
    sorter: (a, b) => a.carrier.localeCompare(b.carrier),
  },
  {
    title: "Origin",
    dataIndex: "origin",
    render: (origin) => (
      <>
        <strong>{origin.companyName}</strong>
        {/* <div>{origin.address}</div>
        <div>{origin.contact}</div>
        <div>Window: {new Date(origin.windowStart).toLocaleString()} - {new Date(origin.windowEnd).toLocaleString()}</div> */}
      </>
    ),
    sorter: (a, b) => a.origin.companyName.localeCompare(b.origin.companyName),
  },
  {
    title: "Destination",
    dataIndex: "destination",
    render: (destination) => (
      <>
        <strong>{destination.companyName}</strong>
        {/* <div>{destination.address}</div> */}
      </>
    ),
    sorter: (a, b) => a.destination.companyName.localeCompare(b.destination.companyName),
  },
  {
    title: "Weight (lbs)",
    dataIndex: "weight",
    sorter: (a, b) => a.weight - b.weight,
  },
  {
    title: "Equipment",
    dataIndex: "equipment",
    sorter: (a, b) => a.equipment.localeCompare(b.equipment),
  },
  {
    title: "Rate ($)",
    dataIndex: "rate",
    sorter: (a, b) => a.rate - b.rate,
  },
  // {
  //   title: "Action",
  //   render: () => (
  //     <div className="dropdown dropdown-action text-end">
  //       <Link
  //         to="#"
  //         className="action-icon dropdown-toggle"
  //         data-bs-toggle="dropdown"
  //         aria-expanded="false"
  //       >
  //         <i className="material-icons">more_vert</i>
  //       </Link>
  //       <div className="dropdown-menu dropdown-menu-right">
  //         <Link className="dropdown-item" to="#" data-bs-toggle="modal" data-bs-target="#edit_entry">
  //           <i className="fa fa-pencil m-r-5" /> Edit
  //         </Link>
  //         <Link className="dropdown-item" to="#" data-bs-toggle="modal" data-bs-target="#delete_entry">
  //           <i className="fa fa-trash m-r-5" /> Delete
  //         </Link>
  //       </div>
  //     </div>
  //   ),
  // },
];

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };


  const optionSort = [
    { value: 'Germany', label: 'Germany' },
    { value: 'USA', label: 'USA' },
    { value: 'Canada', label: 'Canada' },
    { value: 'India', label: 'India' },
    { value: 'China', label: 'China' }
  ];

  const optionsSortValue = [
    { value: 'sortAlphabet', label: 'Sort By Alphabet' },
    { value: 'ascending', label: 'Ascending' },
    { value: 'descending', label: 'Descending' },
    { value: 'recentlyViewed', label: 'Recently Viewed' },
    { value: 'recentlyAdded', label: 'Recently Added' }
  ];

  const handleLabelClick = () => {
    setFocused(true);
  };

  const handleInputBlur = () => {
    if (inputValue === "") {
      setFocused(false);
    }
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value !== "" && !focused) {
      setFocused(true);
    }
  };

  const handleLabelClickTwo = () => {
    setFocusedTwo(true);
  };

  const handleInputBlurTwo = () => {
    if (inputValueTwo === "") {
      setFocusedTwo(false);
    }
  };
  const handleInputChangeTwo = (e) => {
    const value = e.target.value;
    setInputValueTwo(value);
    if (value !== "" && !focusedTwo) {
      setFocusedTwo(true);
    }
  };

  const handleLabelClickThree = () => {
    setFocusedThree(true);
  };

  const handleInputBlurThree = () => {
    if (inputValueThree === "") {
      setFocusedThree(false);
    }
  };
  const handleInputChangeThree = (e) => {
    const value = e.target.value;
    setInputValueThree(value);
    if (value !== "" && !focusedThree) {
      setFocusedThree(true);
    }
  };

  const initialSettings = {
    endDate: new Date("2020-08-11T12:30:00.000Z"),
    ranges: {
      "Last 30 Days": [
        new Date("2020-07-12T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      "Last 7 Days": [
        new Date("2020-08-04T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      "Last Month": [
        new Date("2020-06-30T18:30:00.000Z"),
        new Date("2020-07-31T18:29:59.999Z"),
      ],
      "This Month": [
        new Date("2020-07-31T18:30:00.000Z"),
        new Date("2020-08-31T18:29:59.999Z"),
      ],
      Today: [
        new Date("2020-08-10T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      Yesterday: [
        new Date("2020-08-09T04:57:17.076Z"),
        new Date("2020-08-09T04:57:17.076Z"),
      ],
    },
    startDate: new Date("2020-08-04T04:57:17.076Z"), // Set "Last 7 Days" as default
    timePicker: false,
  };



  const [isFullScreen, setFullScreen] = useState(false);
  const maximizeBtnRef = useRef(null);

  // useEffect(() => {
  //   const handleClick = () => {
  //     if (!document.fullscreenElement) {
  //       document.documentElement.requestFullscreen();
  //       setFullScreen(true);
  //     } else {
  //       if (document.exitFullscreen) {
  //         document.exitFullscreen();
  //         setFullScreen(false);
  //       }
  //     }
  //   };

  //   const cleanup = () => {
  //     if (isFullScreen && document.exitFullscreen) {
  //       document.exitFullscreen();
  //       setFullScreen(false);
  //     }
  //   };

  //   const maximizeBtn = maximizeBtnRef.current;
  //   maximizeBtn.addEventListener('click', handleClick);

  //   // Cleanup function to remove the event listener and exit fullscreen on component unmount
  //   return () => {
  //     maximizeBtn.removeEventListener('click', handleClick);
  //     cleanup();
  //   };
  // }, [isFullScreen]);


  const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
        color: state.isFocused ? "#fff" : "#000",
        "&:hover": {
            backgroundColor: "#ff9b44",
        },
    }),
};

  return (


    <div className="page-wrapper">
      {/* Page Content */}
      <div className="content container-fluid">
        {/* Page Header */}
        {/* <div className="page-header">
          <div className="row align-items-center">
            <div className="col-md-4">
              <h3 className="page-title">Shipments</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/admin-dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item active">Shipments</li>
              </ul>
            </div>
            <div className="col-md-8 float-end ms-auto">
              <div className="d-flex title-head">
                <div className="view-icons">
                  <Link to="#" className="grid-view btn btn-link"><i className="las la-redo-alt" /></Link>
                  <Link to="#" className="list-view btn btn-link" id="collapse-header" ref={maximizeBtnRef}><i className="las la-expand-arrows-alt" /></Link>
                  <Link to="#" className={fieldInputs ? "list-view btn btn-link active-filter" : "list-view btn btn-link"} id="filter_search" onClick={() => setFieldInputs(!fieldInputs)}><i className="las la-filter" /></Link>
                </div>
                <div className="form-sort">
                  <Link to="#" className="list-view btn btn-link" data-bs-toggle="modal" data-bs-target="#export"><i className="las la-file-export" />Export</Link>
                </div>
                <Link to="#" className="btn add-btn" data-bs-toggle="modal" data-bs-target="#add_company"><i className="la la-plus-circle" /> Add Company</Link>
              </div>
            </div>
          </div>
        </div> */}
        {/* /Page Header */}
        {/* Search Filter */}
          {/* /Search Filter */}
          <div className="filter-section d-flex justify-content-between align-items-center mb-4">
            {/* Search Bar on Left */}
              <div className="search-set d-flex align-items-center" >
                <div className="search-input" >
                  <Link to="#" className="btn btn-searchset ">
                    <i className="las la-search " />
                  </Link>
                  <div className="dataTables_filter ">
                    <label>
                      {" "}
                      <input
                        type="search"
                        className="form-control form-control-sm"
                        placeholder="Search"
                      />
                    </label>
                  </div>
                </div>
              </div>

            {/* Filters and Sorting on Right */}
            <div className="filter-options d-flex align-items-center">
              {/* Sort by Alphabet */}
              <div className="form-sort me-3">
                <i className="las la-sort-alpha-up-alt me-1" />
                <Select
                  className="form-sort-two"
                  options={[
                    { value: 'asc', label: 'A-Z' },
                    { value: 'desc', label: 'Z-A' },
                  ]}
                  placeholder="Sort Alphabetically"
                  styles={customStyles}
                />
              </div>

              {/* Filter Dropdown */}
              <div className={`form-sorts dropdown me-3 ${isDropdownOpen ? 'table-filter-show' : ''}`}>
                <Link
                  onClick={handleToggleDropdown}
                  to="#"
                  className="dropdown-toggle"
                  id="table-filter"
                >
                  <i className="las la-filter me-2" />
                  Filter
                </Link>
                <div className="filter-dropdown-menu">
                  <div className="filter-set-view">
                    <div className="filter-set-head">
                      <h4>Filter Options</h4>
                    </div>
                    <div className="accordion" id="accordionExample">
                      {/* Rating Filter */}
                      <div className="filter-set-content">
                        <div className="filter-set-content-head">
                          <Link
                            to="#"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseRating"
                            aria-expanded="true"
                            aria-controls="collapseRating"
                          >
                            Rating
                            <i className="las la-angle-right" />
                          </Link>
                        </div>
                        <div
                          className="filter-set-contents accordion-collapse collapse show"
                          id="collapseRating"
                          data-bs-parent="#accordionExample"
                        >
                          <ul>
                            {[5, 4, 3, 2, 1].map((rating) => (
                              <li key={rating}>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="rating d-flex align-items-center">
                                  {Array.from({ length: rating }, (_, i) => (
                                    <Star key={i} size={18} color="#FFBC34" fill="#FFBC34" />
                                  ))}
                                  {Array.from({ length: 5 - rating }, (_, i) => (
                                    <Star key={i} size={18} color="#E2E4E6" fill="#E2E4E6" />
                                  ))}
                                  <span className="ms-2">{rating}.0</span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      {/* Owner Filter */}
                      <div className="filter-set-content">
                        <div className="filter-set-content-head">
                          <Link
                            to="#"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOwner"
                            aria-expanded="false"
                            aria-controls="collapseOwner"
                          >
                            Owner
                            <i className="las la-angle-right" />
                          </Link>
                        </div>
                        <div
                          className="filter-set-contents accordion-collapse collapse"
                          id="collapseOwner"
                          data-bs-parent="#accordionExample"
                        >
                          <ul>
                            {['Hendry', 'Guillory', 'Jami', 'Theresa', 'Espinosa'].map((owner) => (
                              <li key={owner}>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>{owner}</h5>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      {/* Tags Filter */}
                      <div className="filter-set-content">
                        <div className="filter-set-content-head">
                          <Link
                            to="#"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTags"
                            aria-expanded="false"
                            aria-controls="collapseTags"
                          >
                            Tags
                            <i className="las la-angle-right" />
                          </Link>
                        </div>
                        <div
                          className="filter-set-contents accordion-collapse collapse"
                          id="collapseTags"
                          data-bs-parent="#accordionExample"
                        >
                          <ul>
                            {['Promotion', 'Rated', 'Rejected', 'Collab', 'Calls'].map((tag) => (
                              <li key={tag}>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>{tag}</h5>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="filter-reset-btns mt-3">
                      <button className="btn btn-light me-2">Reset</button>
                      <button className="btn btn-primary">Apply</button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Add Customer */}
              <div>
                <Link
                  to="#"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add_contact"
                >
                  <i className="la la-plus-circle" /> Add Shipment
                </Link>
              </div>
            </div>
          </div>
        {/* <hr /> */}
        <br />

        <div className="row ">
          <div className="col-md-12">
            <div className="table-responsive">
              <Table
                className="table-striped"
                columns={columns}
                dataSource={data}
                rowKey={(record) => record.id}
                onRow={(record) => ({
                  onClick: () => navigate(`/shipment-details`, { state: { shipmentDetails: record } }),
                })}
              />
            </div>
          </div>
        </div>
      </div>
      <CompaniesModal />
      <AddNotes/>
    </div>



  )
}

export default LoadShipmentPage