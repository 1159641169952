
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { avatar1, avatar19, avatar20, avatar21, profileuploadimg } from '../../../Routes/ImagePath';
import Select from 'react-select';
import ReactTagInput from '../../../views/pages/Crm/ReactTagInput';
import { ENDPOINT } from '../../../Config/Constants';

const AddContact = () => {

    const handlePaymentHistoryChange = (index, key, value) => {
        const newPaymentHistory = formData.customer_payment_history.map((history, i) => {
            if (i === index) {
                return {
                    ...history,
                    [key]: value
                };
            }
            return history;
        });
        setFormData({
            ...formData,
            customer_payment_history: newPaymentHistory
        });
    };

    const addPaymentHistory = () => {
        setFormData({
            ...formData,
            customer_payment_history: [
                ...formData.customer_payment_history,
                {
                    invoice_number: '',
                    date: '',
                    amount: 0.00,
                    status: 'Pending'
                }
            ]
        });
    };

    const [formData, setFormData] = useState({
        customer_company_name: '',
        customer_address: '',
        customer_type: '',
        customer_industry: {
            sector: '',
            specialization: ''
        },
        customer_dot_number: '',
        customer_mc_number: '',
        customer_primary_contact_name: '',
        customer_primary_contact_phone: '',
        customer_primary_contact_email: '',
        customer_billing_address: {
            street: '',
            city: '',
            state: '',
            zip: '',
            country: ''
        },
        customer_tax_id: '',
        customer_billing_contact: '',
        customer_billing_contact_phone: '',
        customer_billing_contact_email: '',
        customer_payment_terms: '',
        customer_preferred_comm_method: '',
        customer_contract_status: false,
        customer_payment_history: []
    });

    const [fieldOne, setFieldOne] = useState(true);
    const [fieldTwo, setFieldTwo] = useState(false);
    const [fieldThree, setFieldThree] = useState(false);
    const [fieldFour, setFieldFour] = useState(false);

    const FieldsetTwo = () => {
        setFieldOne(false);
        setFieldTwo(true);
        setFieldThree(false);
        setFieldFour(false);
    };

    const FieldsetThree = () => {
        setFieldOne(false);
        setFieldTwo(false);
        setFieldThree(true);
        setFieldFour(false);
    };

    const FieldsetFour = () => {
        setFieldOne(false);
        setFieldTwo(false);
        setFieldThree(false);
        setFieldFour(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleNestedChange = (parent, key, value) => {
        setFormData({
            ...formData,
            [parent]: {
                ...formData[parent],
                [key]: value
            }
        });
    };

    const handleSubmit = async () => {
        try {
            const response = await fetch(`${ENDPOINT}/api/customers`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                // Handle success
                console.log('Customer added successfully');
            } else {
                // Handle error
                console.error('Failed to add customer');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div>
            {/* Add Contact */}
            <div
                className="modal custom-modal fade custom-modal-two modal-padding"
                id="add_contact"
                role="dialog"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header header-border justify-content-between p-0">
                            <h5 className="modal-title">Add New Contact</h5>
                            <button
                                type="button"
                                className="btn-close position-static"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="add-details-wizard">
                                <ul id="progressbar" className="progress-bar-wizard">
                                    <li className={fieldOne ? "active" : ""}>
                                        <span>
                                            <i className="la la-user-tie" />
                                        </span>
                                        <div className="multi-step-info">
                                            <h6>Basic Info</h6>
                                        </div>
                                    </li>
                                    <li className={fieldTwo ? "active" : ""}>
                                        <span>
                                            <i className="la la-map-marker" />
                                        </span>
                                        <div className="multi-step-info">
                                            <h6>Address</h6>
                                        </div>
                                    </li>
                                    <li className={fieldThree ? "active" : ""}>
                                        <div className="multi-step-icon">
                                            <span>
                                                <i className="la la-icons" />
                                            </span>
                                        </div>
                                        <div className="multi-step-info">
                                            <h6>Social Profiles</h6>
                                        </div>
                                    </li>
                                    <li className={fieldFour ? "active" : ""}>
                                        <div className="multi-step-icon">
                                            <span>
                                                <i className="la la-images" />
                                            </span>
                                        </div>
                                        <div className="multi-step-info">
                                            <h6>Access</h6>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="add-info-fieldset">
                                <fieldset id="first-field" style={{ display: fieldOne ? "block" : "none" }}>
                                    <form>
                                        <div className="form-upload-profile">
                                            <h6 className="">
                                                Profile Image <span> *</span>
                                            </h6>
                                            <div className="profile-pic-upload">
                                                <div className="profile-pic">
                                                    <span>
                                                        <img
                                                            src={profileuploadimg}
                                                            alt="Img"
                                                        />
                                                    </span>
                                                </div>
                                                <div className="employee-field">
                                                    <div className="mb-0">
                                                        <div className="image-upload mb-0">
                                                            <input type="file" />
                                                            <div className="image-uploads">
                                                                <h4>Upload</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="img-reset-btn">
                                                        <Link to="#">Reset</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="contact-input-set">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Company Name <span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="text" name="customer_company_name" value={formData.customer_company_name} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Address <span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="text" name="customer_address" value={formData.customer_address} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Type <span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="text" name="customer_type" value={formData.customer_type} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Industry Sector <span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="text" name="customer_industry_sector" value={formData.customer_industry.sector} onChange={(e) => handleNestedChange('customer_industry', 'sector', e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Industry Specialization <span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="text" name="customer_industry_specialization" value={formData.customer_industry.specialization} onChange={(e) => handleNestedChange('customer_industry', 'specialization', e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            DOT Number <span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="text" name="customer_dot_number" value={formData.customer_dot_number} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            MC Number <span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="text" name="customer_mc_number" value={formData.customer_mc_number} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Primary Contact Name <span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="text" name="customer_primary_contact_name" value={formData.customer_primary_contact_name} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Primary Contact Phone <span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="text" name="customer_primary_contact_phone" value={formData.customer_primary_contact_phone} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Primary Contact Email <span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="email" name="customer_primary_contact_email" value={formData.customer_primary_contact_email} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 text-end form-wizard-button">
                                                    <button
                                                        className="button btn-lights reset-btn"
                                                        type="reset"
                                                    >
                                                        Reset
                                                    </button>
                                                    <button
                                                        className="btn btn-primary wizard-next-btn"
                                                        type="button" onClick={FieldsetTwo}
                                                    >
                                                        Save &amp; Next
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </fieldset>

                                <fieldset style={{ display: fieldTwo ? "block" : "none" }}>
                                    <form>
                                        <div className="contact-input-set">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Billing Street Address<span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="text" name="customer_billing_address_street" value={formData.customer_billing_address.street} onChange={(e) => handleNestedChange('customer_billing_address', 'street', e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Billing City <span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="text" name="customer_billing_address_city" value={formData.customer_billing_address.city} onChange={(e) => handleNestedChange('customer_billing_address', 'city', e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Billing State / Province{" "}
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="text" name="customer_billing_address_state" value={formData.customer_billing_address.state} onChange={(e) => handleNestedChange('customer_billing_address', 'state', e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Billing Country <span className="text-danger">*</span>
                                                        </label>
                                                        <input className="form-control" type="text" name="customer_billing_address_country" value={formData.customer_billing_address.country} onChange={(e) => handleNestedChange('customer_billing_address', 'country', e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Billing Zipcode <span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="text" name="customer_billing_address_zip" value={formData.customer_billing_address.zip} onChange={(e) => handleNestedChange('customer_billing_address', 'zip', e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Tax ID <span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="text" name="customer_tax_id" value={formData.customer_tax_id} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Billing Contact <span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="text" name="customer_billing_contact" value={formData.customer_billing_contact} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Billing Contact Phone <span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="text" name="customer_billing_contact_phone" value={formData.customer_billing_contact_phone} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Billing Contact Email <span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="email" name="customer_billing_contact_email" value={formData.customer_billing_contact_email} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Payment Terms <span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="text" name="customer_payment_terms" value={formData.customer_payment_terms} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Preferred Communication Method <span className="text-danger"> *</span>
                                                        </label>
                                                        <input className="form-control" type="text" name="customer_preferred_comm_method" value={formData.customer_preferred_comm_method} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Contract Status <span className="text-danger"> *</span>
                                                        </label>
                                                        <div className="status-toggle small-toggle-btn d-flex align-items-center">
                                                            <span className="me-2 label-text">Active</span>
                                                            <input
                                                                type="checkbox"
                                                                id="contract_status"
                                                                className="check"
                                                                checked={formData.customer_contract_status}
                                                                onChange={(e) => setFormData({ ...formData, customer_contract_status: e.target.checked })}
                                                            />
                                                            <label htmlFor="contract_status" className="checktoggle" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 text-end form-wizard-button">
                                                    <button
                                                        className="button btn-lights reset-btn"
                                                        type="reset"
                                                    >
                                                        Reset
                                                    </button>
                                                    <button
                                                        className="btn btn-primary wizard-next-btn"
                                                        type="button" onClick={FieldsetThree}
                                                    >
                                                        Save &amp; Next
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </fieldset>

                                <fieldset style={{ display: fieldThree ? "block" : "none" }}>
                                    <form>
                                        <div className="contact-input-set">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">Payment History</label>
                                                        <div className="payment-history-list">
                                                            {formData.customer_payment_history.map((history, index) => (
                                                                <div key={index} className="mb-3">
                                                                    <div className="row">
                                                                        <div className="col-md-3">
                                                                            <input className="form-control" type="text" placeholder="Invoice Number" value={history.invoice_number} onChange={(e) => handlePaymentHistoryChange(index, 'invoice_number', e.target.value)} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <input className="form-control" type="date" placeholder="Date" value={history.date} onChange={(e) => handlePaymentHistoryChange(index, 'date', e.target.value)} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <input className="form-control" type="number" step="0.01" placeholder="Amount" value={history.amount} onChange={(e) => handlePaymentHistoryChange(index, 'amount', parseFloat(e.target.value))} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <select className="form-select" value={history.status} onChange={(e) => handlePaymentHistoryChange(index, 'status', e.target.value)}>
                                                                                <option value="Paid">Paid</option>
                                                                                <option value="Pending">Pending</option>
                                                                                <option value="Overdue">Overdue</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <div className="col-lg-12 text-end form-wizard-button">
                                                                <button
                                                                    className="btn btn-secondary add-payment-history-btn"
                                                                    type="button"
                                                                    onClick={addPaymentHistory}
                                                                >
                                                                    Add Payment History
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 text-end form-wizard-button">
                                                    <button
                                                        className="button btn-lights reset-btn"
                                                        type="reset"
                                                    >
                                                        Reset
                                                    </button>
                                                    <button
                                                        className="btn btn-primary wizard-next-btn"
                                                        type="button" onClick={FieldsetFour}
                                                    >
                                                        Save &amp; Next
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </fieldset>

                                <fieldset style={{ display: fieldFour ? "block" : "none" }}>
                                    <form>
                                        <div className="contact-input-set">
                                            <div className="input-blocks add-products">
                                                <label className="mb-3">Visibility</label>
                                                <div className="access-info-tab">
                                                    <ul
                                                        className="nav nav-pills"
                                                        id="pills-tab1"
                                                        role="tablist"
                                                    >
                                                        <li className="nav-item" role="presentation">
                                                            <span
                                                                className="custom_radio mb-0"
                                                                id="pills-public-tab"
                                                                data-bs-toggle="pill"
                                                                data-bs-target="#pills-public"
                                                                role="tab"
                                                                aria-controls="pills-public"
                                                                aria-selected="true"
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    className="form-control"
                                                                    name="public"
                                                                    defaultChecked=""
                                                                />
                                                                <span className="checkmark" /> Public
                                                            </span>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <span
                                                                className="custom_radio mb-0"
                                                                id="pills-private-tab"
                                                                data-bs-toggle="pill"
                                                                data-bs-target="#pills-private"
                                                                role="tab"
                                                                aria-controls="pills-private"
                                                                aria-selected="false"
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    className="form-control"
                                                                    name="private"
                                                                />
                                                                <span className="checkmark" /> Private
                                                            </span>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <span
                                                                className="custom_radio mb-0 active"
                                                                id="pills-select-people-tab"
                                                                data-bs-toggle="pill"
                                                                data-bs-target="#pills-select-people"
                                                                role="tab"
                                                                aria-controls="pills-select-people"
                                                                aria-selected="false"
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    className="form-control"
                                                                    name="select-people"
                                                                />
                                                                <span className="checkmark" /> Select People
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="tab-content" id="pills-tabContent">
                                                <div
                                                    className="tab-pane fade"
                                                    id="pills-public"
                                                    role="tabpanel"
                                                    aria-labelledby="pills-public-tab"
                                                ></div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="pills-private"
                                                    role="tabpanel"
                                                    aria-labelledby="pills-private-tab"
                                                ></div>
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="pills-select-people"
                                                    role="tabpanel"
                                                    aria-labelledby="pills-select-people-tab"
                                                >
                                                    <div className="people-select-tab">
                                                        <h3>Select People</h3>
                                                        <div className="select-people-checkbox">
                                                            <label className="custom_check">
                                                                <input type="checkbox" />
                                                                <span className="checkmark" />
                                                                <span className="people-profile">
                                                                    <img
                                                                        src={avatar19}
                                                                        alt="Img"
                                                                    />
                                                                    <Link to="#">Darlee Robertson</Link>
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="select-people-checkbox">
                                                            <label className="custom_check">
                                                                <input type="checkbox" />
                                                                <span className="checkmark" />
                                                                <span className="people-profile">
                                                                    <img
                                                                        src={avatar20}
                                                                        alt="Img"
                                                                    />
                                                                    <Link to="#">Sharon Roy</Link>
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="select-people-checkbox">
                                                            <label className="custom_check">
                                                                <input type="checkbox" />
                                                                <span className="checkmark" />
                                                                <span className="people-profile">
                                                                    <img
                                                                        src={avatar21}
                                                                        alt="Img"
                                                                    />
                                                                    <Link to="#">Vaughan</Link>
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="select-people-checkbox">
                                                            <label className="custom_check">
                                                                <input type="checkbox" />
                                                                <span className="checkmark" />
                                                                <span className="people-profile">
                                                                    <img
                                                                        src={avatar1}
                                                                        alt="Img"
                                                                    />
                                                                    <Link to="#">Jessica</Link>
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="select-confirm-btn">
                                                            <Link to="#" className="btn danger-btn">
                                                                Confirm
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 className="mb-3">Status</h5>
                                            <div className="status-radio-btns d-flex mb-3">
                                                <div className="people-status-radio">
                                                    <input
                                                        type="radio"
                                                        className="status-radio"
                                                        id="test4"
                                                        name="radio-group"
                                                        defaultChecked=""
                                                    />
                                                    <label htmlFor="test4">Active</label>
                                                </div>
                                                <div className="people-status-radio">
                                                    <input
                                                        type="radio"
                                                        className="status-radio"
                                                        id="test5"
                                                        name="radio-group"
                                                    />
                                                    <label htmlFor="test5">Private</label>
                                                </div>
                                                <div className="people-status-radio">
                                                    <input
                                                        type="radio"
                                                        className="status-radio"
                                                        id="test6"
                                                        name="radio-group"
                                                    />
                                                    <label htmlFor="test6">Inactive</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 text-center form-wizard-button">
                                                <button
                                                    className="button btn-lights reset-btn"
                                                    type="reset"
                                                >
                                                    Reset
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    type="button"
                                                    onClick={handleSubmit}
                                                >
                                                    Save Contact
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Contact */}
            {/* Success Contact */}
            <div className="modal custom-modal fade" id="success_msg" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="success-message text-center">
                                <div className="success-popup-icon">
                                    <i className="la la-user-shield" />
                                </div>
                                <h3>Contact Created Successfully!!!</h3>
                                <p>View the details of contact</p>
                                <div className="col-lg-12 text-center form-wizard-button">
                                    <Link to="#" className="button btn-lights" data-bs-dismiss="modal">
                                        Close
                                    </Link>
                                    <Link to="#" className="btn btn-primary">
                                        View Details
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Success Contact */}
        </div>
    );

   
};

export default AddContact;