import { Table } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import ExportLeads from '../../../components/modelpopup/Crm/ExportLeads';
import AddLeads from '../../../components/modelpopup/Crm/AddLeads';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Select from 'react-select';
import CrmDeleteModal from '../../../components/modelpopup/Crm/CrmDeleteModal';
import EditLeads from '../../../components/modelpopup/Crm/EditLeads';
import { Star } from 'react-feather';
import SearchBox from '../../../components/SearchBox';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import AddEquipmentModal from './AddEquipmentModal';
import { ENDPOINT } from '../../../Config/Constants';


const ViewDetailsModal = ({ show, handleClose, selectedRecord }) => {
  return (
    <Modal show={show} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Details of Equipment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedRecord ? (
          <div>
            <p><strong>ID:</strong> {selectedRecord.id}</p>
            <p><strong>Type:</strong> {selectedRecord.type}</p>
            <p><strong>Capacity (Weight):</strong> {selectedRecord.capacity.weight} kg</p>
            <p><strong>Capacity (Volume):</strong> {selectedRecord.capacity.volume} m³</p>
            <p><strong>Location:</strong> {selectedRecord.location.latitude || "N/A"}, {selectedRecord.location.longitude || "N/A"}</p>
            <p><strong>Created At:</strong> {new Date(selectedRecord.createdAt).toLocaleString()}</p>
            <p><strong>Updated At:</strong> {new Date(selectedRecord.updatedAt).toLocaleString()}</p>
            <h5>Maintenance History:</h5>
            {selectedRecord.maintenanceHistory && selectedRecord.maintenanceHistory.length > 0 ? (
              <ul>
                {selectedRecord.maintenanceHistory.map((entry, index) => (
                  <li key={index}>
                    <strong>Date:</strong> {entry.date} - <strong>Service:</strong> {entry.service}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No maintenance history available.</p>
            )}
          </div>
        ) : (
          <p>No details available.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


const EquipmentInventory = () => {
  const [equipmentList, setEquipmentList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [expandedEquipment, setExpandedEquipment] = useState(null)
  const [editingEquipment, setEditingEquipment] = useState(null)
  const [newEquipment, setNewEquipment] = useState(null)
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);

const addEquipment = async (equipmentData) => {
    try {
        const response = await fetch(`${ENDPOINT}/api/equipment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(equipmentData),
        })

        if (response.ok) {
            const data = await response.json()
            // Update the equipment list with the newly added equipment
            setEquipmentList((prevList) => [...prevList, data])
            setNewEquipment(null) // Close the modal
        } else {
            const error = await response.json()
            alert('Error: ' + error.message)
        }
    } catch (error) {
        alert('Error: ' + error.message)
    }
}

  const handleViewDetails = (record) => {
    setSelectedRecord(record);
    setShowDetailsModal(true);
  };

  const handleCloseDetails = () => {
    setSelectedRecord(null);
    setShowDetailsModal(false);
  };
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  useEffect(() => {
    const fetchEquipment = async () => {
        try {
            const response = await axios.get(`${ENDPOINT}/api/equipment`)
            setEquipmentList(response.data)
            setIsLoading(false)
            console.log("Equipmeny: ", equipmentList)
        } catch (err) {
            setError('Failed to fetch equipment. Please try again later.')
            setIsLoading(false)
        }
    }
    fetchEquipment()
}, [])

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: "Type",
    dataIndex: "type",
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
  {
    title: "Capacity (Weight)",
    dataIndex: ["capacity", "weight"],
    render: (weight) => `${weight} kg`,
    sorter: (a, b) => a.capacity.weight - b.capacity.weight,
  },
  {
    title: "Capacity (Volume)",
    dataIndex: ["capacity", "volume"],
    render: (volume) => `${volume} m³`,
    sorter: (a, b) => a.capacity.volume - b.capacity.volume,
  },
  {
    title: "Location",
    dataIndex: "location",
    render: (location) => `${location.latitude || "N/A"}, ${location.longitude || "N/A"}`,
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    render: (date) => new Date(date).toLocaleString(),
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
  },
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    render: (date) => new Date(date).toLocaleString(),
    sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
  },
  {
    title: "Action",
    render: (record) => (
      <div className="dropdown dropdown-action text-end">
        <a
          href="#"
          className="action-icon dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="material-icons">more_vert</i>
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          <a
            className="dropdown-item"
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#edit_vehicle"
          >
            <i className="fa fa-pencil m-r-5" /> Edit
          </a>
          <a
            className="dropdown-item"
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#delete_vehicle"
          >
            <i className="fa fa-trash m-r-5" /> Delete
          </a>
          <a
              className="dropdown-item"
              href="#"
              onClick={() => handleViewDetails(record)}
            >
              <i className="fa fa-eye m-r-5" /> View Details
            </a>
        </div>
      </div>
    ),
  },
];

  const initialSettings = {
    endDate: new Date("2020-08-11T12:30:00.000Z"),
    ranges: {
      "Last 30 Days": [
        new Date("2020-07-12T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      "Last 7 Days": [
        new Date("2020-08-04T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      "Last Month": [
        new Date("2020-06-30T18:30:00.000Z"),
        new Date("2020-07-31T18:29:59.999Z"),
      ],
      "This Month": [
        new Date("2020-07-31T18:30:00.000Z"),
        new Date("2020-08-31T18:29:59.999Z"),
      ],
      Today: [
        new Date("2020-08-10T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      Yesterday: [
        new Date("2020-08-09T04:57:17.076Z"),
        new Date("2020-08-09T04:57:17.076Z"),
      ],
    },
    startDate: new Date("2020-08-04T04:57:17.076Z"), // Set "Last 7 Days" as default
    timePicker: false,
  };
  const status = [
    { value: '--Select--', label: '--Select--' },
    { value: 'Closed', label: 'Closed' },
    { value: 'Not Contacted', label: 'Not Contacted' },
    { value: 'Contacted', label: 'Contacted' },
    { value: 'Lost', label: 'Lost' }
  ];
  const companyName = [
    { value: '--Select--', label: '--Select--' },
    { value: 'NovaWaveLLC', label: 'NovaWaveLLC' },
    { value: 'SilverHawk', label: 'SilverHawk' },
    { value: 'SummitPeak', label: 'SummitPeak' },
    { value: 'HarborView', label: 'HarborView' },
    { value: 'Redwood Inc', label: 'Redwood Inc' },
  ];
  const sortoption = [
    { value: 'Sort By Alphabet', label: 'Sort By Alphabet' },
    { value: 'Ascending', label: 'Ascending' },
    { value: 'Descending', label: 'Descending' },
    { value: 'Recently Viewed', label: 'Recently Viewed' },
    { value: 'Recently Added', label: 'Recently Added' },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };
  const [inputValue, setInputValue] = useState("");

  const [focused, setFocused] = useState(false);

  const handleLabelClick = () => {
    setFocused(true);
  };
  const handleInputBlur = () => {
    if (inputValue === "") {
      setFocused(false);
    }
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value !== "" && !focused) {
      setFocused(true);
    }
  };
  //
  const [inputValue1, setInputValue1] = useState("");

  const [focused1, setFocused1] = useState(false);

  const handleLabelClick1 = () => {
    setFocused1(true);
  };
  const handleInputBlur1 = () => {
    if (inputValue1 === "") {
      setFocused1(false);
    }
  };
  const handleInputChange1 = (e) => {
    const value = e.target.value;
    setInputValue1(value);
    if (value !== "" && !focused1) {
      setFocused1(true);
    }
  };
  //filter
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">

          {/* /Search Filter */}
          <div className="filter-section d-flex justify-content-between align-items-center mb-4">
            {/* Search Bar on Left */}
              <div className="search-set d-flex align-items-center" >
                <div className="search-input" >
                  <Link to="#" className="btn btn-searchset ">
                    <i className="las la-search " />
                  </Link>
                  <div className="dataTables_filter ">
                    <label>
                      {" "}
                      <input
                        type="search"
                        className="form-control form-control-sm"
                        placeholder="Search"
                      />
                    </label>
                  </div>
                </div>
              </div>

            {/* Filters and Sorting on Right */}
            <div className="filter-options d-flex align-items-center">
              {/* Sort by Alphabet */}
              <div className="form-sort me-3">
                <i className="las la-sort-alpha-up-alt me-1" />
                <Select
                  className="form-sort-two"
                  options={[
                    { value: 'asc', label: 'A-Z' },
                    { value: 'desc', label: 'Z-A' },
                  ]}
                  placeholder="Sort Alphabetically"
                  styles={customStyles}
                />
              </div>

              {/* Filter Dropdown */}
              <div className={`form-sorts dropdown me-3 ${isDropdownOpen ? 'table-filter-show' : ''}`}>
                <Link
                  onClick={handleToggleDropdown}
                  to="#"
                  className="dropdown-toggle"
                  id="table-filter"
                >
                  <i className="las la-filter me-2" />
                  Filter
                </Link>
                <div className="filter-dropdown-menu">
                  <div className="filter-set-view">
                    <div className="filter-set-head">
                      <h4>Filter Options</h4>
                    </div>
                    <div className="accordion" id="accordionExample">
                      {/* Rating Filter */}
                      <div className="filter-set-content">
                        <div className="filter-set-content-head">
                          <Link
                            to="#"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseRating"
                            aria-expanded="true"
                            aria-controls="collapseRating"
                          >
                            Rating
                            <i className="las la-angle-right" />
                          </Link>
                        </div>
                        <div
                          className="filter-set-contents accordion-collapse collapse show"
                          id="collapseRating"
                          data-bs-parent="#accordionExample"
                        >
                          <ul>
                            {[5, 4, 3, 2, 1].map((rating) => (
                              <li key={rating}>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="rating d-flex align-items-center">
                                  {Array.from({ length: rating }, (_, i) => (
                                    <Star key={i} size={18} color="#FFBC34" fill="#FFBC34" />
                                  ))}
                                  {Array.from({ length: 5 - rating }, (_, i) => (
                                    <Star key={i} size={18} color="#E2E4E6" fill="#E2E4E6" />
                                  ))}
                                  <span className="ms-2">{rating}.0</span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      {/* Owner Filter */}
                      <div className="filter-set-content">
                        <div className="filter-set-content-head">
                          <Link
                            to="#"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOwner"
                            aria-expanded="false"
                            aria-controls="collapseOwner"
                          >
                            Owner
                            <i className="las la-angle-right" />
                          </Link>
                        </div>
                        <div
                          className="filter-set-contents accordion-collapse collapse"
                          id="collapseOwner"
                          data-bs-parent="#accordionExample"
                        >
                          <ul>
                            {['Hendry', 'Guillory', 'Jami', 'Theresa', 'Espinosa'].map((owner) => (
                              <li key={owner}>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>{owner}</h5>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      {/* Tags Filter */}
                      <div className="filter-set-content">
                        <div className="filter-set-content-head">
                          <Link
                            to="#"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTags"
                            aria-expanded="false"
                            aria-controls="collapseTags"
                          >
                            Tags
                            <i className="las la-angle-right" />
                          </Link>
                        </div>
                        <div
                          className="filter-set-contents accordion-collapse collapse"
                          id="collapseTags"
                          data-bs-parent="#accordionExample"
                        >
                          <ul>
                            {['Promotion', 'Rated', 'Rejected', 'Collab', 'Calls'].map((tag) => (
                              <li key={tag}>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>{tag}</h5>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="filter-reset-btns mt-3">
                      <button className="btn btn-light me-2">Reset</button>
                      <button className="btn btn-primary">Apply</button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Add Customer */}
              <div>
                <Link
                  to="#"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add_contact"
                >
                  <i className="la la-plus-circle" /> Add Customers
                </Link>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table table-striped custom-table datatable contact-table"
                  columns={columns}
                  dataSource={equipmentList}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
        <ExportLeads />
        <AddLeads />
        <EditLeads />
        <CrmDeleteModal />
        <ViewDetailsModal
        show={showDetailsModal}
        handleClose={handleCloseDetails}
        selectedRecord={selectedRecord}
      />
      <AddEquipmentModal
                showModal={!!newEquipment}
                handleModalClose={() => setNewEquipment(null)}
                addEquipment={addEquipment}
            />
      </div>
      {/* /Page Content */}
    </div>

  )
}

export default EquipmentInventory
