import React, { useState, useEffect } from "react";
import { Card, Table, Badge, Pagination, Collapse, Row, Col, Dropdown } from "react-bootstrap";
import { Truck, Clock, MapPin, AlertCircle, CheckCircle, Eye, Edit, Trash2, MoreVertical } from "react-feather";
import { Line } from "react-chartjs-2";

const ActivityLogTab = ({ driverDetails }) => {
  const [activityLogs, setActivityLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedRow, setExpandedRow] = useState(null);
  const logsPerPage = 10;

  useEffect(() => {
    const generateMockLogs = () => {
      const mockLogs = [
        {
          id: 1,
          timestamp: "2024-02-15T08:30:00Z",
          type: "Load Pickup",
          description: "Picked up load #1245 from Dallas Distribution Center",
          status: "Completed",
          routeStops: ["Dallas", "Austin", "San Antonio", "Houston"],
          graphData: [10, 40, 70, 100],
        },
        {
          id: 2,
          timestamp: "2024-02-14T15:45:00Z",
          type: "HOS Log",
          description: "Logged 8 hours of driving time",
          status: "Compliant",
          routeStops: ["Houston", "Baton Rouge", "New Orleans"],
          graphData: [0, 50, 100],
        },
        // Additional mock logs...
      ];
      setActivityLogs(mockLogs);
    };

    generateMockLogs();
  }, [driverDetails.driver_id]);

  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = activityLogs.slice(indexOfFirstLog, indexOfLastLog);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getStatusBadgeVariant = (status) => {
    switch (status) {
      case "Completed":
        return "success";
      case "Pending":
        return "warning";
      case "Reported":
        return "info";
      case "Passed":
        return "success";
      case "Compliant":
        return "primary";
      default:
        return "secondary";
    }
  };

  const getActivityIcon = (type) => {
    switch (type) {
      case "Load Pickup":
        return <Truck size={16} />;
      case "Load Delivery":
        return <Truck size={16} />;
      case "HOS Log":
        return <Clock size={16} />;
      case "Vehicle Inspection":
        return <CheckCircle size={16} />;
      case "Route Planning":
        return <MapPin size={16} />;
      case "Training":
        return <AlertCircle size={16} />;
      default:
        return null;
    }
  };

  const handleRowClick = (id) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  return (
    <Card className="shadow-sm">
      <Card.Header className="text-white bg-light d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Activity Logs</h5>
        <small>{driverDetails.fullName} - Recent Activities</small>
      </Card.Header>
      <Card.Body className="p-0">
        <Table hover responsive className="mb-0">
          <thead>
            <tr>
              <th>Date/Time</th>
              <th>Activity Type</th>
              <th>Description</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentLogs.map((log) => (
              <React.Fragment key={log.id}>
                <tr onClick={() => handleRowClick(log.id)} className="cursor-pointer">
                  <td>
                    {new Date(log.timestamp).toLocaleString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      {getActivityIcon(log.type)}
                      <span className="ms-2">{log.type}</span>
                    </div>
                  </td>
                  <td>{log.description}</td>
                  <td>
                    <Badge bg={getStatusBadgeVariant(log.status)} className="text-white">
                      {log.status}
                    </Badge>
                  </td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="outline-secondary" size="sm">
                        <MoreVertical size={16} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Eye size={16} className="mr-2" /> View Details
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Edit size={16} className="mr-2" /> Edit
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          className="text-danger"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Trash2 size={16} className="mr-2" /> Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                {/* Expandable Row */}
                <tr>
                  <td colSpan="5">
                    <Collapse in={expandedRow === log.id}>
                      <Card className="border-0">
                        <Card.Body className="bg-light rounded">
                          <Row className="no-gutters">
                            <Col md={12} className="pr-md-2">
                              <h6 className="text-muted mb-3">Shipment Details</h6>
                              <div className="d-flex flex-column">
                                <div className="mb-2 d-flex">
                                  <strong>Route Stops:</strong>
                                  <span>&nbsp;&nbsp;{log.routeStops.join(" → ")}</span>
                                </div>
                              </div>
                              <h6 className="text-muted mt-3 mb-3">Journey Graph</h6>
                              <div className="p-3">
                                <Line
                                  data={{
                                    labels: log.routeStops,
                                    datasets: [
                                      {
                                        label: "Progress (%)",
                                        data: log.graphData,
                                        fill: false,
                                        borderColor: "blue",
                                        tension: 0.1,
                                      },
                                    ],
                                  }}
                                  options={{
                                    responsive: true,
                                    scales: {
                                      y: {
                                        beginAtZero: true,
                                        max: 100,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Collapse>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer>
        <div className="d-flex justify-content-between align-items-center">
          <small className="text-muted">
            Showing {indexOfFirstLog + 1} to {Math.min(indexOfLastLog, activityLogs.length)} of {activityLogs.length} logs
          </small>
          <Pagination size="sm" className="mb-0">
            {Array.from({
              length: Math.ceil(activityLogs.length / logsPerPage),
            }).map((_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default ActivityLogTab;
