import React, { useState } from 'react';
import { Card, Form, Button, Row, Col, Container, Accordion, useAccordionButton } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ENDPOINT } from '../../../Config/Constants';

const AddShipment = ({ customerDetails }) => {
    const navigate = useNavigate()
    const location = useLocation();
    const customerID = location.state?.customer_id;
    const [shipmentData, setShipmentData] = useState({
        customer_id: customerID,
        load_origin_company_name: '',
        load_origin_address: {
            street: '',
            city: '',
            state: '',
            zipcode: ''
        },
        load_origin_numbers: '',
        load_origin_contact: '',
        load_origin_window_start: '',
        load_origin_window_end: '',
        load_stop_company_name: '',
        load_stop_address: {
            street: '',
            city: '',
            state: '',
            zipcode: ''
        },
        load_stop_numbers: '',
        load_stop_contact: '',
        load_stop_window_start: '',
        load_stop_window_end: '',
        load_destination_company_name: '',
        load_destination_address: {
            street: '',
            city: '',
            state: '',
            zipcode: ''
        },
        load_destination_numbers: '',
        load_destination_contact: '',
        load_destination_window_start: '',
        load_destination_window_end: '',
        load_equipment_type: '',
        load_weight: null,
        load_stop_address_m: [
            {
                city: "",
                state: "",
                street: "",
                zipcode: ""
            },
        ],
        load_ltl: false,
        load_dimensions: {
            length: null,
            width: null,
            height: null
        },
        load_status: 'Scheduled',
        load_tender_details: null,
        load_acceptance_history: null,
    });

    const [drivers] = useState([
        {
            id: 'driver1',
            name: 'John Doe',
            equipment: [
                { id: 'equip1', type: 'Flatbed' },
                { id: 'equip2', type: 'Refrigerated Truck' },
            ],
        },
        {
            id: 'driver2',
            name: 'Jane Smith',
            equipment: [
                { id: 'equip3', type: 'Dry Van' },
                { id: 'equip4', type: 'Tanker' },
            ],
        },
        {
            id: 'driver3',
            name: 'Mike Johnson',
            equipment: [
                { id: 'equip5', type: 'Box Truck' },
                { id: 'equip6', type: 'Step Deck' },
            ],
        },
    ]);

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey);

        return (
            <Card.Header
                onClick={decoratedOnClick}
                className="d-flex justify-content-between align-items-center cursor-pointer"
                style={{ backgroundColor: '#f8f9fa', padding: '15px' }}
            >
                {children}
                <i className="fas fa-chevron-down"></i>
            </Card.Header>
        );
    }

    const [selectedDriver, setSelectedDriver] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const nameParts = name.split('.');

        setShipmentData(prev => {
            if (nameParts.length === 2) {
                if (nameParts[0] === 'load_dimensions') {
                    return {
                        ...prev,
                        [nameParts[0]]: {
                            ...prev[nameParts[0]],
                            [nameParts[1]]: value === '' ? null : Number(value),
                        }
                    };
                }

                return {
                    ...prev,
                    [nameParts[0]]: {
                        ...prev[nameParts[0]],
                        [nameParts[1]]: value,
                    }
                };
            }

            switch (name) {
                case 'load_weight':
                    return { ...prev, [name]: value === '' ? null : Number(value) };
                case 'load_ltl':
                    return { ...prev, [name]: value === 'true' };
                default:
                    return { ...prev, [name]: value };
            }
        });
    };

    const handleAddShipment = async (e) => {
        e.preventDefault();

        // Validate required fields
        const requiredFields = [
            'load_origin_company_name',
            'load_origin_address.street',
            'load_origin_address.city',
            'load_destination_company_name',
            'load_destination_address.street',
            'load_destination_address.city',
            'load_equipment_type'
        ];


        const formatDate = (dateString) => {
            if (!dateString) return null;
            try {
                // Convert datetime-local to ISO 8601 format
                return new Date(dateString).toISOString();
            } catch {
                return null;
            }
        };

        const isValid = requiredFields.every(field => {
            const [parent, child] = field.split('.');
            return child
                ? shipmentData[parent][child] !== ''
                : shipmentData[parent] !== '';
        });

        if (!isValid) {
            alert('Please fill in all required fields');
            return;
        }

        // Prepare payload with proper formatting
        const payload = {
            ...shipmentData,
            load_weight: shipmentData.load_weight || 0,
            load_dimensions: {
                length: shipmentData.load_dimensions.length || 0,
                width: shipmentData.load_dimensions.width || 0,
                height: shipmentData.load_dimensions.height || 0
            },
            load_tender_details: shipmentData.load_tender_details || undefined,
            load_acceptance_history: shipmentData.load_acceptance_history || undefined,
            load_origin_window_start: formatDate(shipmentData.load_origin_window_start),
            load_origin_window_end: formatDate(shipmentData.load_origin_window_end),
            load_stop_window_start: formatDate(shipmentData.load_stop_window_start),
            load_stop_window_end: formatDate(shipmentData.load_stop_window_end),
            load_destination_window_start: formatDate(shipmentData.load_destination_window_start),
            load_destination_window_end: formatDate(shipmentData.load_destination_window_end),
        };

        try {
            const response = await fetch(`${ENDPOINT}/api/loads`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to create shipment: ${errorText}`);
            }

            const data = await response.json();
            navigate(-1);
            // console.log('Shipment created successfully:', data);
        } catch (error) {
            console.error('Error creating shipment:', error);
            alert(error.message);
        }
    };

    const handleStopChange = (index, e) => {
        const { name, value } = e.target;
        setShipmentData((prev) => {
            const updatedStops = [...prev.load_stop_address_m];
            updatedStops[index][name] = value;
            return { ...prev, load_stop_address_m: updatedStops };
        });
    };

    const addStop = () => {
        setShipmentData((prev) => ({
            ...prev,
            load_stop_address_m: [
                ...prev.load_stop_address_m,
                { street: '', city: '', state: '', zipcode: '', window_start: '', window_end: '', contact: '' },
            ],
        }));
    };

    const removeStop = (index) => {
        setShipmentData((prev) => ({
            ...prev,
            load_stop_address_m: prev.load_stop_address_m.filter((_, i) => i !== index),
        }));
    };

    const handleDriverChange = (e) => {
        const driverId = e.target.value;
        const selectedDriver = drivers.find((driver) => driver.id === driverId);
        setShipmentData((prev) => ({ ...prev, driver_id: driverId }));
        setSelectedDriver(selectedDriver);
    };


    const renderAddressSection = (type, displayName) => (
        <Accordion.Item eventKey={type}>
            <Accordion.Header>{`${displayName} Details`}</Accordion.Header>
            <Accordion.Body>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                                type="text"
                                name={`${type}_company_name`}
                                value={shipmentData[`${type}_company_name`]}
                                onChange={handleInputChange}
                                placeholder={`Enter ${displayName} company name`}
                                required={type !== 'load_stop'}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Contact</Form.Label>
                            <Form.Control
                                type="text"
                                name={`${type}_contact`}
                                value={shipmentData[`${type}_contact`]}
                                onChange={handleInputChange}
                                placeholder="Name, email, phone"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Street</Form.Label>
                            <Form.Control
                                type="text"
                                name={`${type}_address.street`}
                                value={shipmentData[`${type}_address`]?.street || ''}
                                onChange={handleInputChange}
                                placeholder={`Enter ${displayName} street`}
                                required={type !== 'load_stop'}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                type="text"
                                name={`${type}_address.city`}
                                value={shipmentData[`${type}_address`]?.city || ''}
                                onChange={handleInputChange}
                                placeholder={`Enter ${displayName} city`}
                                required={type !== 'load_stop'}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>State</Form.Label>
                            <Form.Control
                                type="text"
                                name={`${type}_address.state`}
                                value={shipmentData[`${type}_address`]?.state || ''}
                                onChange={handleInputChange}
                                placeholder={`Enter ${displayName} state`}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Zipcode</Form.Label>
                            <Form.Control
                                type="text"
                                name={`${type}_address.zipcode`}
                                value={shipmentData[`${type}_address`]?.zipcode || ''}
                                onChange={handleInputChange}
                                placeholder={`Enter ${displayName} zipcode`}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Window Start</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                name={`${type}_window_start`}
                                value={shipmentData[`${type}_window_start`]}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    );


    return (
        <div>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <Container className="my-4">
                        <Card>
                            <Card.Body>
                                <Card.Subtitle className="mb-3 mt-4 fs-20">Add Shipment</Card.Subtitle>

                                <Form onSubmit={handleAddShipment}>
                                    <Accordion defaultActiveKey="pickup">
                                        {renderAddressSection('load_origin', 'Pickup')}
                                        {renderAddressSection('load_destination', 'Delivery')}</Accordion>
                                    <Card.Subtitle className="mb-3 mt-4 fs-20">Stops Details</Card.Subtitle>
                                    <Accordion defaultActiveKey="1">
                                        {shipmentData.load_stop_address_m.map((stop, index) => (
                                            <Accordion.Item eventKey={index.toString()} key={index}>
                                                <Accordion.Header>Stop {index + 1}</Accordion.Header>
                                                <Accordion.Body>
                                                    <Row className='mb-3'>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Company Name</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="company_name"
                                                                        value={stop.company_name || ''}
                                                                        onChange={(e) => handleStopChange(index, e)}
                                                                        placeholder="Enter Pickup Company Name"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Contact Name</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="contact_name"
                                                                        value={stop.contact_name || ''}
                                                                        onChange={(e) => handleStopChange(index, e)}
                                                                        placeholder="Contact Name"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Email</Form.Label>
                                                                    <Form.Control
                                                                        type="email"
                                                                        name="contact_email"
                                                                        value={stop.contact_email || ''}
                                                                        onChange={(e) => handleStopChange(index, e)}
                                                                        placeholder="Contact Email"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Phone</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="contact_phone"
                                                                        value={stop.contact_phone || ''}
                                                                        onChange={(e) => handleStopChange(index, e)}
                                                                        placeholder="Contact Phone"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Street</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="street"
                                                                        value={stop.street || ''}
                                                                        onChange={(e) => handleStopChange(index, e)}
                                                                        placeholder="Enter Pickup Street"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>City</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="city"
                                                                        value={stop.city || ''}
                                                                        onChange={(e) => handleStopChange(index, e)}
                                                                        placeholder="Enter Pickup City"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={4}>
                                                                <Form.Group>
                                                                    <Form.Label>State</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="state"
                                                                        value={stop.state || ''}
                                                                        onChange={(e) => handleStopChange(index, e)}
                                                                        placeholder="Enter Pickup State"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={4}>
                                                                <Form.Group>
                                                                    <Form.Label>Zipcode</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="zipcode"
                                                                        value={stop.zipcode || ''}
                                                                        onChange={(e) => handleStopChange(index, e)}
                                                                        placeholder="Enter Pickup Zipcode"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={4}>
                                                                <Form.Group>
                                                                    <Form.Label>Window Start</Form.Label>
                                                                    <Form.Control
                                                                        type="time"
                                                                        name="window_start"
                                                                        value={stop.window_start || ''}
                                                                        onChange={(e) => handleStopChange(index, e)}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={4}>
                                                                <Button
                                                                    variant="secondary"
                                                                    className="mt-4"
                                                                    onClick={() => removeStop(index)}
                                                                >
                                                                    Remove Stop
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                    <Button variant="primary" onClick={addStop} className="mt-4">
                                        Add Stop
                                    </Button>

                                    <Card.Subtitle className="mb-3 mt-3 fs-20">Driver Details</Card.Subtitle>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>Select Driver</Form.Label>
                                                    <Form.Select value={shipmentData.driver_id} onChange={handleDriverChange} required>
                                                        <option value="">Select Driver</option>
                                                        {drivers.map((driver) => (
                                                            <option key={driver.id} value={driver.id}>
                                                                {driver.name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            {selectedDriver && (
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Driver Equipment</Form.Label>
                                                        <Form.Select>
                                                            {selectedDriver.equipment.map((equip) => (
                                                                <option key={equip.id} value={equip.type}>
                                                                    {equip.type}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            )}
                                        </Row>
    
                                        <Card.Subtitle className="mb-3 mt-3 fs-20">Shipment Details</Card.Subtitle>
                                        <Row>
                                            <Col md={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Origin Numbers</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="load_origin_numbers"
                                                        value={shipmentData.load_origin_numbers}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter origin numbers"
                                                        required
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Stop Numbers</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="load_stop_numbers"
                                                        value={shipmentData.load_stop_numbers}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter stop numbers"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Destination Numbers</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="load_destination_numbers"
                                                        value={shipmentData.load_destination_numbers}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter destination numbers"
                                                        required
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Equipment Type</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="load_equipment_type"
                                                        value={shipmentData.load_equipment_type}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter equipment type"
                                                        required
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Weight (lbs)</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="load_weight"
                                                        value={shipmentData.load_weight}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter weight"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Dimensions (L x W x H)</Form.Label>
                                                    <Row>
                                                        <Col md={4}>
                                                            <Form.Control
                                                                type="number"
                                                                name="load_dimensions.length"
                                                                value={shipmentData.load_dimensions.length}
                                                                onChange={handleInputChange}
                                                                placeholder="Length"
                                                            />
                                                        </Col>
                                                        <Col md={4}>
                                                            <Form.Control
                                                                type="number"
                                                                name="load_dimensions.width"
                                                                value={shipmentData.load_dimensions.width}
                                                                onChange={handleInputChange}
                                                                placeholder="Width"
                                                            />
                                                        </Col>
                                                        <Col md={4}>
                                                            <Form.Control
                                                                type="number"
                                                                name="load_dimensions.height"
                                                                value={shipmentData.load_dimensions.height}
                                                                onChange={handleInputChange}
                                                                placeholder="Height"
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Select
                                                        name="load_status"
                                                        value={shipmentData.load_status}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option>Scheduled</option>
                                                        <option>In Transit</option>
                                                        <option>Delivered</option>
                                                        <option>Cancelled</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Button variant="primary" type="submit" className="w-100 mt-3">
                                            Create Shipment
                                        </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                </div>
            </div>
        </div>

    );
};

export default AddShipment;