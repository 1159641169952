import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { PlusCircle, X } from 'react-feather'; // You can change the icon to any preferred one
import { ENDPOINT } from '../../../Config/Constants';

const AddEquipmentModal = ({ showModal, handleModalClose }) => {
  const [newEquipment, setNewEquipment] = useState({
    type: '',
    capacity: { weight: '', volume: '' },
    location: { latitude: '', longitude: '' },
    maintenanceHistory: [{ date: '', service: '' }],
  });

  // Handle input changes for all fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name.includes('capacity')) {
      const field = name.split('.')[1]; // Extract 'weight' or 'volume'
      setNewEquipment((prevState) => ({
        ...prevState,
        capacity: {
          ...prevState.capacity,
          [field]: value,
        },
      }));
    } else if (name.includes('location')) {
      const field = name.split('.')[1]; // Extract 'latitude' or 'longitude'
      setNewEquipment((prevState) => ({
        ...prevState,
        location: {
          ...prevState.location,
          [field]: value,
        },
      }));
    } else {
      setNewEquipment((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  // Handle maintenance history input change
  const handleMaintenanceChange = (index, e) => {
    const { name, value } = e.target;
    const newHistory = [...newEquipment.maintenanceHistory];
    newHistory[index] = { ...newHistory[index], [name]: value };
    setNewEquipment((prevState) => ({
      ...prevState,
      maintenanceHistory: newHistory,
    }));
  };

  // Add a new maintenance record
  const addMaintenanceField = () => {
    setNewEquipment((prevState) => ({
      ...prevState,
      maintenanceHistory: [...prevState.maintenanceHistory, { date: '', service: '' }],
    }));
  };

  // Remove a maintenance record
  const removeMaintenanceField = (index) => {
    const newHistory = [...newEquipment.maintenanceHistory];
    newHistory.splice(index, 1);
    setNewEquipment((prevState) => ({
      ...prevState,
      maintenanceHistory: newHistory,
    }));
  };

  // Submit the form and call the API to add equipment
  const handleSubmit = () => {
    addEquipment(newEquipment);  // Call API function
    handleModalClose();  // Close modal after submitting
  };

  // API call to add new equipment
  const addEquipment = async (equipmentData) => {
    try {
      const response = await fetch(`${ENDPOINT}/api/equipment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(equipmentData),
      });

      if (response.ok) {
        const data = await response.json();
        alert('Equipment added successfully');
        // Reset the form after successful submission
        setNewEquipment({
          type: '',
          capacity: { weight: '', volume: '' },
          location: { latitude: '', longitude: '' },
          maintenanceHistory: [{ date: '', service: '' }],
        });
      } else {
        const error = await response.json();
        alert('Error: ' + error.message);
      }
    } catch (error) {
      alert('Error: ' + error.message);
    }
  };

  return (
    <Modal show={showModal} centered onHide={handleModalClose} size="lg">
      <Modal.Header>
        <Modal.Title>Add Equipment</Modal.Title>
        <Button variant="link" onClick={handleModalClose}>
          <X size={24} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  type="text"
                  name="type"
                  value={newEquipment.type}
                  onChange={handleInputChange}
                  placeholder="Enter equipment type (e.g., Truck)"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Weight</Form.Label>
                <Form.Control
                  type="number"
                  name="capacity.weight"
                  value={newEquipment.capacity.weight}
                  onChange={handleInputChange}
                  placeholder="Enter weight (kg)"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Volume</Form.Label>
                <Form.Control
                  type="number"
                  name="capacity.volume"
                  value={newEquipment.capacity.volume}
                  onChange={handleInputChange}
                  placeholder="Enter volume (m³)"
                />
              </Form.Group>
            </Col>
            {/* <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Latitude</Form.Label>
                <Form.Control
                  type="text"
                  name="location.latitude"
                  value={newEquipment.location.latitude}
                  onChange={handleInputChange}
                  placeholder="Enter latitude"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Longitude</Form.Label>
                <Form.Control
                  type="text"
                  name="location.longitude"
                  value={newEquipment.location.longitude}
                  onChange={handleInputChange}
                  placeholder="Enter longitude"
                />
              </Form.Group>
            </Col> */}
          </Row>
          <h5 className="mb-3">Maintenance History</h5>
          {newEquipment.maintenanceHistory.map((history, index) => (
            <Row key={index}>
              <Col md={5}>
                <Form.Group className="mb-3">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={history.date}
                    onChange={(e) => handleMaintenanceChange(index, e)}
                  />
                </Form.Group>
              </Col>
              <Col md={5}>
                <Form.Group className="mb-3">
                  <Form.Label>Service</Form.Label>
                  <Form.Control
                    type="text"
                    name="service"
                    value={history.service}
                    onChange={(e) => handleMaintenanceChange(index, e)}
                    placeholder="Enter service"
                  />
                </Form.Group>
              </Col>
              <Col md={2} clas="mt-3">
                <Button variant="danger " onClick={() => removeMaintenanceField(index)}>
                  Remove
                </Button>
              </Col>
            </Row>
          ))}
          <Button variant="secondary" onClick={addMaintenanceField}>
            Add Maintenance Record
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Equipment
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEquipmentModal;
